import React from 'react';
import ServicesSection from '../components/Services';
import ServicesOffer from '../basics/ServicesOffer';
import ChooseParteners from '../basics/ChooseParteners';
import RecognizedSection from '../basics/RecognizedSectionContainer';
import ProjectManagementSection from '../basics/ProjectManagementSection';
import CustomerSatisfactionSection from '../basics/CustomerSatisfactionSection';
import FAQSection from '../components/Faq';

const Services = () => {
  return (
    <>
      <ServicesSection />
      <ServicesOffer />
      <ChooseParteners />
      <RecognizedSection />
      <ProjectManagementSection />
      <CustomerSatisfactionSection />
      <FAQSection />
    </>
  );
};

export default Services;
