import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const useCurrentRoute = () => {
  const location = useLocation();
  const path = location.pathname.split('/').filter(Boolean);
  return path.length ? path[path.length - 1] : 'home'; // Default to 'home' if path is empty
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
