import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useCurrentRoute } from '../utilities';
import withScrollAnimation from './withScrollAnimation';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const TestimonialsSectionContainer = styled.section`
  position: relative;
  padding: 3.125rem 1.25rem;
  text-align: center;
  background-color: #f8f8f8;
  overflow: hidden;
  max-width: 73.5rem;
  margin: auto;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.25rem;
    background: linear-gradient(
      to right,
      rgba(70, 130, 180, 0.5),
      rgba(70, 130, 180, 0.1)
    );
    z-index: -1;
  }
  @media (max-width: 768px) {
    /* {currentRoute === 'contact'}
    margin-top: 15rem; */
    margin-top: ${(props) =>
      props.currentRoute === 'contact' ? '25rem' : '2rem'};
  }
`;

const Title = styled.h2`
  position: relative;
  color: #87ceeb;
  font-size: 1.3rem;
  margin-bottom: 0.625rem;
  background-size: 200% 200%;
  display: inline-block;
  padding: 0.4375rem 0.8125rem;
  border: 1px solid #87ceeb;
  border-radius: 15px;
  font-weight: bold;
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const Subtitle = styled.h3`
  position: relative;
  color: black;
  font-size: 2rem;
  margin-bottom: 1.875rem;
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const DotsBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.25rem;
  background: radial-gradient(#87ceeb 1px, transparent 1px), #f8f8f8;
  background-size: 1.25rem 1.25rem;
  z-index: 0;
`;

const TestimonialsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  width: 100%;
`;

const TestimonialCard = styled.div`
  background-color: white;
  border-radius: 0.625rem;
  padding: 1.25rem;
  margin: 0.625rem;
  flex: 0 0 28%;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  min-height: 18.75rem;
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 768px) {
    line-height: 2;
    font-size: 0.6rem;
    max-height: 2rem;
  }

  p {
    margin: 0;
    color: #555;
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.6rem;
    @media (max-width: 768px) {
      font-size: 0.6rem;
      line-height: 1.1rem;
    }
  }

  .author {
    display: flex;
    align-items: left;
    margin-bottom: 0.625rem;
    flex-direction: column;
    text-align: left;
  }

  .author img {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    margin-bottom: 0.625rem;
  }

  .author-info {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .author-info p {
    margin: 0;
    color: #1b3a4b;
    font-weight: bold;
    word-wrap: break-word;
  }

  .author-info span {
    color: #999;
    font-size: 0.9rem;
    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
  }
`;

const TestimonialsSection = () => {
  const [testimonials, setTestimonials] = useState([
    {
      name: 'Sara Khan',
      company: 'Upwork',
      text: 'Working with Tech Star Growth has been a pleasure. Their user-centric approach made the entire process seamless. They truly understand our needs and deliver beyond expectations.',
    },
    {
      name: 'Aiman',
      company: 'Fiver',
      text: "I was struggling with my assignments until I found Tech Star Growth. Their expert writers delivered top-notch work on time, every time. Thanks to them, I've seen a significant improvement in my grades.",
    },
    {
      name: 'Muhammad H',
      company: 'Facebook',
      text: "Tech Star Growth has been instrumental for our business's growth. Their dedicated team handles our operations and strategy with utmost professionalism. Highly recommend!",
    },
    {
      name: 'Saad Ali',
      company: 'Instagram',
      text: "Tech Star Growth's CSR initiatives have made a real difference in our community. Their dedication is inspiring, and we're proud to partner with them in creating positive change.",
    },
    {
      name: 'John Doe',
      company: 'LinkedIn',
      text: 'Tech Star Growth has transformed our business approach. Their insights and strategies are top-notch and have driven our success.',
    },
    {
      name: 'Jane Smith',
      company: 'Google',
      text: 'The team at Tech Star Growth is exceptionally professional and effective. Their support has been invaluable to our operations.',
    },
  ]);

  const currentRoute = useCurrentRoute();

  useEffect(() => {
    const interval = setInterval(() => {
      setTestimonials((prevTestimonials) => {
        const newTestimonials = [...prevTestimonials];
        const firstTestimonial = newTestimonials.shift();
        newTestimonials.push(firstTestimonial);
        return newTestimonials;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <TestimonialsSectionContainer id='testimonials' currentRoute={currentRoute}>
      <DotsBackground />
      <Title>Testimonials</Title>
      {currentRoute === 'about' && <Subtitle>What they said about us</Subtitle>}
      <TestimonialsWrapper>
        <TestimonialsContainer style={{ transform: `translateX(-50%)` }}>
          {testimonials.slice(0, 5).map((testimonial, i) => (
            <TestimonialCard key={i} visible={i > 0 && i < 4}>
              <div className='author'>
                <img
                  src='https://via.placeholder.com/50'
                  alt={testimonial.name}
                />
                <div className='author-info'>
                  <p>{testimonial.name}</p>
                  <span>{testimonial.company}</span>
                </div>
              </div>
              <p>{testimonial.text}</p>
            </TestimonialCard>
          ))}
        </TestimonialsContainer>
      </TestimonialsWrapper>
    </TestimonialsSectionContainer>
  );
};

export default withScrollAnimation(TestimonialsSection);
