import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus, FaMinus } from 'react-icons/fa';
import withScrollAnimation from '../basics/withScrollAnimation';

const FAQSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.25rem;
  max-width: 1176px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  padding: 0.25rem 15rem;
  border: 1px solid #87ceeb; /* Custom blue color */
  color: #87ceeb;
  border-radius: 0.9375rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.2rem 1.5rem;
  }
  /* @media (max-width: 450px) {
    font-size: 0.575rem;
    padding: 0.2rem 1.5rem;
  } */
`;

const Subtitle = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`;

const FAQBox = styled.div`
  width: 100%;
  max-width: 43rem;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 0 0.75rem;
  }
`;

const FAQItem = styled.div`
  width: 100%;
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
  transition: color 0;
  color: black;

  &:hover {
    color: #87ceeb; /* Custom blue color on hover */
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Answer = styled.div`
  height: ${(props) => (props.isOpen ? `${props.height}px` : '0')};
  overflow: hidden;
  transition: height 0.3s ease;
  padding: ${(props) => (props.isOpen ? '1rem' : '0')};
  color: black;

  @media (max-width: 768px) {
    padding: ${(props) => (props.isOpen ? '0.75rem' : '0')};
  }
`;

const FAQs = [
  {
    question: 'What types of writing services does Tech Star Growth offer?',
    answer:
      'Tech Star Growth offers a variety of writing services including content writing, copywriting, technical writing, and more.',
  },
  {
    question: 'Can Tech Star Growth handle large-scale data entry projects?',
    answer:
      'Yes, absolutely. Tech Star Growth has extensive experience in data entry, including handling large volumes of data with precision and efficiency. Our team is equipped to manage various data entry tasks, ensuring accuracy and reliability.',
  },
  {
    question: 'What CSR initiatives does Tech Star Growth engage in?',
    answer:
      'Tech Star Growth is committed to corporate social responsibility, engaging in various initiatives that support community development, sustainability, and ethical business practices.',
  },
  {
    question:
      'How does Tech Star Growth approach SEO and digital marketing services?',
    answer:
      'Our team uses a data-driven approach to SEO and digital marketing, ensuring that strategies are tailored to meet the specific needs and goals of each client. We stay updated with the latest trends and best practices to deliver effective results.',
  },
  {
    question: 'What sets Tech Star Growth apart in terms of service quality?',
    answer:
      'Tech Star Growth stands out for its commitment to quality, customer satisfaction, and innovative solutions. Our experienced team, attention to detail, and dedication to excellence ensure that clients receive top-notch services.',
  },
];

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (openIndex !== null && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [openIndex]);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <FAQSectionContainer>
      <Title>Frequently Asked Questions</Title>
      <Subtitle>Got any questions? we’re here to help</Subtitle>
      <FAQBox>
        {FAQs.map((faq, index) => (
          <FAQItem key={index}>
            <Question onClick={() => handleToggle(index)}>
              {faq.question}
              {openIndex === index ? <FaMinus /> : <FaPlus />}
            </Question>
            <Answer
              ref={contentRef}
              isOpen={openIndex === index}
              height={height}
            >
              {faq.answer}
            </Answer>
          </FAQItem>
        ))}
      </FAQBox>
    </FAQSectionContainer>
  );
};

export default withScrollAnimation(FAQSection);
