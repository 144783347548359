import React from 'react';
import styled, { keyframes } from 'styled-components';
import satisfactionImage from '../assets/customer_satisfaction_services.png'; // replace with your image path
import withScrollAnimation from './withScrollAnimation';
import { useNavigate } from 'react-router';

const SectionContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 3rem 1rem;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.875rem 0.625rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-left: 2rem;
  @media (max-width: 48rem) {
    padding-left: 0;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 100%;
    height: auto;
    border-radius: 0.625rem;
  }
`;

const CategoryBadge = styled.div`
  border: 1px solid #87ceeb;
  color: #87ceeb;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    margin-bottom: 0;
    margin-top: 2rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  color: black; /* Custom blue color */
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #333;
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const KnowMoreButton = styled.button`
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: 1.25rem;
  @media (max-width: 48rem) {
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
  }
  @media (max-width: 450px) {
    font-size: 0.675rem;
  }
`;

const CustomerSatisfactionSection = () => {
  const navigate = useNavigate();
  return (
    <SectionContainer>
      <ImageContainer>
        <img src={satisfactionImage} alt='Customer Satisfaction' />
      </ImageContainer>
      <TextContainer>
        <CategoryBadge>Customer Satisfaction</CategoryBadge>
        <Title>Your satisfaction is our ultimate goal</Title>
        <Description>
          We strive to exceed your expectations and provide unparalleled
          customer service and support throughout every step of the process.
          When you choose Tech Star Growth, you're not just getting a service
          provider – you're getting a partner dedicated to your success.
        </Description>
        <KnowMoreButton onClick={() => navigate('/about')}>
          Know More
        </KnowMoreButton>
      </TextContainer>
    </SectionContainer>
  );
};

export default withScrollAnimation(CustomerSatisfactionSection);
