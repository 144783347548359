import React from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: 450px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    max-width: 1176px;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    padding: 20px 20px;
  }
`;

const FooterColumn = styled.div`
  flex: 1;
  padding: 10px 20px;
  @media (max-width: 450px) {
    padding: 0;
  }
`;

const FooterLogo = styled.img`
  max-width: 150px;
  margin-bottom: 20px;
  @media (max-width: 450px) {
    margin-bottom: 0;
  }
`;

const FooterTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 10px;
  @media (max-width: 450px) {
    margin-bottom: 0;
    font-size: 1.2rem;
  }
`;

const FooterText = styled.p`
  font-size: 1rem;
  line-height: 1.8rem;
  @media (max-width: 450px) {
    line-height: 1.4rem;
    font-size: 0.9rem;
  }
`;

const FooterLink = styled.a`
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: underline;
  }
`;

const IconWrapper = styled.span`
  margin-right: 0.5rem;
  margin-top: 0.5rem;

`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #e7e7e7;
`;

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterColumn>
          <FooterLogo
            src={require('../assets/logo.png')}
            alt='Tech Star Growth'
          />
          <FooterText>
            Welcome to Tech Star Growth, your premier destination for
            comprehensive online project solutions.
          </FooterText>
          <FooterText>
            <FooterLink href='mailto:techstargrowth@gmail.com'>
              <IconWrapper>
                <FaEnvelope />
              </IconWrapper>
              techstargrowth@gmail.com
            </FooterLink>
          </FooterText>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>Company</FooterTitle>
          <FooterText>Home</FooterText>
          <FooterText>Services</FooterText>
          <FooterText>Register</FooterText>
          <FooterText>About Us</FooterText>
          <FooterText>Contact Us</FooterText>
          <FooterText>Employee Login</FooterText>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>About Tech Star Growth</FooterTitle>
          <FooterText>
            We thrive on pushing boundaries, exploring new technologies, and
            discovering innovative solutions to meet the evolving needs of our
            clients.
          </FooterText>
        </FooterColumn>
      </FooterContainer>
      <FooterBottom>
        <FooterText>©Tech Star Growth. All Rights Reserved.</FooterText>
        <FooterText>
          <FooterLink href='mailto:techstargrowth@gmail.com'>
            <IconWrapper>
              <FaEnvelope />
            </IconWrapper>
            techstargrowth@gmail.com
          </FooterLink>
          {' | '}
          <FooterLink
            href='https://goo.gl/maps/abc123'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconWrapper>
              <FaMapMarkerAlt />
            </IconWrapper>
            MM ALAM Road Block C1 block T Gulberg, Lahore.
          </FooterLink>
        </FooterText>
      </FooterBottom>
    </>
  );
};

export default Footer;
