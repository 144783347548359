import React from 'react';
import HeroSection from '../basics/HeroSection';
import Partners from '../basics/Partners';
import AboutSection from '../basics/AboutSection';
import TestimonialsSection from '../basics/TestimonialsSection';
import IntegrationsSection from '../basics/IntegrationsSection';
import ServicesSection from '../basics/ServicesSection';
import ServicesOffer from '../basics/ServicesOffer';
import RecognizedSection from '../basics/RecognizedSectionContainer';
import JoinSection from '../basics/JoinSection';
import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  color: #1b3a4b;
  font-family: Arial, sans-serif;
`;

const HomePage = () => {
  return (
    <Container>
      <HeroSection />
      <Partners />
      <AboutSection />
      <ServicesSection />
      <ServicesOffer />
      <TestimonialsSection />
      <IntegrationsSection />
      <RecognizedSection />
      <JoinSection />
    </Container>
  );
};

export default HomePage;
