import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import useResponsiveScreen from '../hooks/useResponsiveScreen';
import { useNavigate } from 'react-router-dom';

const NavbarWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  justify-content: center;
  background-color: ${({ open }) =>
    open ? 'white' : 'rgba(255, 255, 255, 0.9)'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: ${({ open }) => (open ? 'none' : 'blur(10px)')};
  transition: all 0.3s ease;

  /* @media (max-width: 768px) {
    overflow: hidden;
    height: 100vh;
  } */
`;

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1176px;
  width: 100%;
  max-height: 80px;
  padding: 30px 0;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

const LogoNavItemsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const NavItems = styled.div`
  display: flex;
  margin-left: 20px;

  @media (max-width: 768px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    margin-top: 4.3rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 92vh;
    background-color: white;
    z-index: 999;
    margin-left: 0;
    opacity: ${({ open }) => (open ? '1' : '0')};
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    
  }
`;

const NavItem = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.1em;
  cursor: pointer;

  &:hover {
    color: #87ceeb;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 1rem 1rem;
    font-size: 1.1rem;
  }
  @media (max-width: 450px) {
    display: block;
    margin: 0.9rem 1rem;
    font-size: 1rem;
  }
`;

const Logo = styled.img`
  height: 100px;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const CompanyName = styled.span`
  display: none;
  font-size: 1.5rem;
  font-family: 'Georgia', serif;
  font-weight: bold;
  color: #4a4a4a;
  margin-left: 10px;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.2rem;
    color: #4a4a4a;
    margin-left: 10px;
  }
  @media (max-width: 450px) {
    display: block;
    font-size: 0.8rem;
    color: #4a4a4a;
    margin-left: 10px;
  }
`;

const Button = styled.button`
  font-family: 'Inter', Sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
  color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(130, 130, 130, 0.48);
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;

  @media (max-width: 768px) {
    position: absolute;
    bottom: 3rem;

    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

const HamburgerIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.5em;
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useResponsiveScreen();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [menuOpen]);

  const handleNavItemClick = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <NavbarWrapper open={menuOpen}>
      <NavbarContainer>
        <LogoNavItemsContainer>
          <Logo src={require('../assets/logo.png')} alt='TechStarGrowth Logo' />
          <CompanyName>ᴛᴇᴄʜ ꜱᴛᴀʀ ɢʀᴏᴡᴛʜ</CompanyName>
          {!isMobile && (
            <NavItems>
              <NavItem to='/'>Home</NavItem>
              <NavItem to='/services'>Services</NavItem>
              <NavItem to='/register'>Register</NavItem>
              <NavItem to='/about'>About Us</NavItem>
              <NavItem to='/contact'>Contact</NavItem>
            </NavItems>
          )}
        </LogoNavItemsContainer>
        {isMobile && (
          <>
            <HamburgerIcon onClick={toggleMenu}>
              {menuOpen ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>
            <NavItems open={menuOpen}>
              <NavItem to='/' onClick={handleNavItemClick}>
                Home
              </NavItem>
              <NavItem to='/services' onClick={handleNavItemClick}>
                Services
              </NavItem>
              <NavItem to='/register' onClick={handleNavItemClick}>
                Register
              </NavItem>
              <NavItem to='/about' onClick={handleNavItemClick}>
                About Us
              </NavItem>
              <NavItem to='/contact' onClick={handleNavItemClick}>
                Contact
              </NavItem>
              {isLoggedIn ? (
                <Button onClick={handleLogout}>Log Out</Button>
              ) : (
                <Button
                  onClick={() => {
                    navigate('/login');
                    handleNavItemClick();
                  }}
                >
                  Log In
                </Button>
              )}
            </NavItems>
          </>
        )}
        {!isMobile &&
          (isLoggedIn ? (
            <Button onClick={handleLogout}>Log Out</Button>
          ) : (
            <Button onClick={() => navigate('/login')}>Log In</Button>
          ))}
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default Navbar;
