import React from 'react';
import styled from 'styled-components';
import fadeInUp from './FadeInUp';
import withScrollAnimation from './withScrollAnimation';
import useResponsiveScreen from '../hooks/useResponsiveScreen';

const AboutSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.125rem 1.25rem;
  max-width: 73.5rem;
  margin: 0 auto;
  animation: ${fadeInUp} 1s ease forwards;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 2.125rem 1.25rem;
  }
  @media (max-width: 450px) {
    padding: 0.125rem 1.25rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 1.25rem;
  animation: ${fadeInUp} 1s ease forwards;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  animation: ${fadeInUp} 1s ease forwards;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 1.25rem;
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  animation: ${fadeInUp} 1s ease forwards;

  @media (min-width: 768px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const AboutImage = styled.img`
  max-width: 100%;
  height: 20rem;
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 768) {
    height: 15rem;
  }
  @media (max-width: 450px) {
    height: 13rem;
  }
`;

const Subtitle = styled.h3`
  border: 1px solid #e8e8e8;
  color: #1ca3da;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const AboutSection = () => {
  const { isMobile } = useResponsiveScreen();

  return (
    <AboutSectionContainer>
      {isMobile ? (
        <>
          <TextContainer>
            <Subtitle>About Us</Subtitle>
            <Title>
              Tech Star Growth: Your trusted partner for efficient and reliable
              solutions.
            </Title>
            <Description>
              Our team embodies a passion for innovation, precision, and client
              satisfaction, ensuring that each project is executed with the
              utmost professionalism and efficiency.
            </Description>
            <Button>Learn More</Button>
          </TextContainer>
          <ImageContainer>
            <AboutImage
              src={require('../assets/about_homePage.png')}
              alt='About Tech Star Growth'
            />
          </ImageContainer>
        </>
      ) : (
        <>
          <ImageContainer>
            <AboutImage
              src={require('../assets/about_homePage.png')}
              alt='About Tech Star Growth'
            />
          </ImageContainer>
          <TextContainer>
            <Subtitle>About Us</Subtitle>
            <Title>
              Tech Star Growth: Your trusted partner for efficient and reliable
              solutions.
            </Title>
            <Description>
              Our team embodies a passion for innovation, precision, and client
              satisfaction, ensuring that each project is executed with the
              utmost professionalism and efficiency.
            </Description>
            <Button>Learn More</Button>
          </TextContainer>
        </>
      )}
    </AboutSectionContainer>
  );
};

export default withScrollAnimation(AboutSection);
