import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import withScrollAnimation from '../basics/withScrollAnimation';

const AdminPageContainer = styled.div`
  padding: 2rem;
  max-width: 1176px;
  margin: 0 auto;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 0.5rem;
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 0.5rem;
`;

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MEGA_SERVER}/api/users`,
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users', error);
      }
    };

    const fetchContacts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MEGA_SERVER}/api/contact/contacts`,
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        );
        console.log(response.data);
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts', error);
      }
    };

    fetchUsers();
    fetchContacts();
  }, []);

  return (
    <AdminPageContainer>
      <Section>
        <SectionTitle>Registered Users</SectionTitle>
        <Table>
          <thead>
            <tr>
              <TableHeader>ID</TableHeader>
              <TableHeader>First Name</TableHeader>
              <TableHeader>Last Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Phone</TableHeader>
              <TableHeader>City</TableHeader>
              <TableHeader>Qualification</TableHeader>
              <TableHeader>Gender</TableHeader>
              <TableHeader>Salary</TableHeader>
              <TableHeader>Registered At</TableHeader>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.city}</TableCell>
                <TableCell>{user.qualification}</TableCell>
                <TableCell>{user.gender}</TableCell>
                <TableCell>{user.salary}</TableCell>
                <TableCell>{new Date(user.createdAt).toISOString()}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Section>
      <Section>
        <SectionTitle>Contact Submissions</SectionTitle>
        <Table>
          <thead>
            <tr>
              <TableHeader>ID</TableHeader>
              <TableHeader>First Name</TableHeader>
              <TableHeader>Last Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Phone</TableHeader>
              <TableHeader>Message</TableHeader>
              <TableHeader>Submitted At</TableHeader>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>{contact.id}</TableCell>
                <TableCell>{contact.firstName}</TableCell>
                <TableCell>{contact.lastName}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone}</TableCell>
                <TableCell>{contact.message}</TableCell>
                <TableCell>{contact.createdAt}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Section>
    </AdminPageContainer>
  );
};

export default withScrollAnimation(AdminPage);
