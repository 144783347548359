import React from 'react'
import RegisterPage from '../components/RegisterForm'
import FAQSection from '../components/Faq';

const Register = () => {
  return (
    <div>
      <RegisterPage />
      <FAQSection/>
    </div>
  );
}

export default Register
