import React from 'react'
import ContactForm from '../components/ContactForm'
import TestimonialsSection from '../basics/TestimonialsSection'
import FAQSection from '../components/Faq'
import JoinSection from '../basics/JoinSection'

const Contact = () => {
  return (
    <>
      <ContactForm/>
      <TestimonialsSection/>
      <FAQSection/>
      <JoinSection/>
    </>
  )
}

export default Contact
