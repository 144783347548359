import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/logo.png'; // replace with your logo path
import fadeInUp from '../basics/FadeInUp';
import withScrollAnimation from '../basics/withScrollAnimation';
import useResponsiveScreen from '../hooks/useResponsiveScreen';

const gradientAnimation = keyframes`
  0% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 10% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
`;

const ServicesSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(30, 144, 255, 0.1) 30%,
    rgba(30, 144, 255, 0.5) 100%
  );
  background-size: 200% 200%;
  animation: ${gradientAnimation} 6s ease infinite;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  border-radius: 0.625rem;
  margin-top: 2.5rem;
  @media (max-width: 48rem) {
    padding: 1.875rem 0.625rem;
  }
  position: relative;
`;

const Dots = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px
  );
  background-size: 1rem 1rem;
  pointer-events: none;
`;

const LogoImage = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 1.25rem;
  animation: ${fadeInUp} 1s ease forwards;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  color: #1b3a4b; /* Custom blue color */
  text-align: center;
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 1.25rem;
  color: #1e90ff; /* Custom blue color */
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 48rem) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 1.5rem;
    margin: 0;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 2.5rem;
  text-align: center;
  color: #333;
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 1.875rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    margin-bottom: 1.875rem;
  }
`;

const ServicesSection = () => {
  const { isMobile } = useResponsiveScreen();

  return (
    <ServicesSectionContainer>
      <Dots />
      <LogoImage src={logo} alt='Tech Star Growth Logo' />
      <Subtitle>Services</Subtitle>
      <Title>
        Customized <span style={{ fontStyle: 'italic' }}>solutions</span> to{' '}
        <br />
        drive success.
      </Title>
      <Description>
        In the dynamic realm of Information Technology, staying ahead requires{' '}
        {!isMobile ? <br /> : ''}
        more than just keeping up with the trends—it demands proactive
        adaptation {!isMobile ? <br /> : ''}and innovation.
      </Description>
    </ServicesSectionContainer>
  );
};

export default withScrollAnimation(ServicesSection);
