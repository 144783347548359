import React from 'react';
import styled from 'styled-components';
import officeImage from '../assets/aboutus_aboutus.png'; // Replace with your actual image path
import partnerImage1 from '../assets/parteners/chain.png';
import partnerImage2 from '../assets/parteners/exeo.png';
import partnerImage3 from '../assets/parteners/natuska.png';
import partnerImage4 from '../assets/parteners/network.png';
import partnerImage5 from '../assets/parteners/saona.png';
import partnerImage6 from '../assets/parteners/vision.png';
import withScrollAnimation from '../basics/withScrollAnimation';

const AboutUsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  position: relative;
  @media (max-width: 48rem) {
    padding: 1.875rem 0.625rem;
    /* max-width: 375px; */
  }
  @media (max-width: 375px) {
    max-width: 375px;
  }
`;

const Box = styled.div`
  width: 100%;
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 0.625rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 9rem;
  background: ${(props) =>
    props.gradient
      ? 'linear-gradient(0deg, rgba(30, 144, 255, 0.1) 0%, rgba(255, 255, 255, 0.9) 100%)'
      : 'white'};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background: radial-gradient(circle, #e8e8e8 1px, transparent 1px);
    background-size: 10px 10px;
  }
  @media (max-width: 48rem) {
    padding: 1.5rem;
  }
  @media (max-width: 450px) {
    height: 23rem;
  }
`;

const _Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 0.625rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 5rem;
  background: ${(props) =>
    props.gradient
      ? 'linear-gradient(0deg, rgba(30, 144, 255, 0.1) 0%, rgba(255, 255, 255, 0.9) 100%)'
      : 'white'};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background: radial-gradient(circle, #e8e8e8 1px, transparent 1px);
    background-size: 10px 10px;
  }
  @media (max-width: 48rem) {
    padding: 1.5rem;
  }
  @media (max-width: 450px) {
    margin-bottom: 0;
    padding: 1.5rem;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 0.625rem;
  z-index: 1;
  @media (max-width: 48rem) {
    top: 35%;
    width: 64%;
  }
  @media (max-width: 450px) {
    top: 37%;
    width: 64%;
    margin-bottom: 10rem;
  }
  @media (max-width: 375px) {
    top: 37%;
    width: 64%;
    margin-bottom: 10rem;
  }
`;

const BigTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: normal;
  color: #1b3a4b; /* Custom blue color */
  text-align: center;
  margin-bottom: 1rem;
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: normal;
  color: #1b3a4b; /* Custom blue color */
  text-align: center;
  margin-top: 7rem;
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    /* margin-bottom: 1.5rem; */
  }
`;

const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    gap: 0;
    margin-bottom: 0;
  }
`;

const Partner = styled.div`
  width: 10rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  opacity: 0.3;
  transition: opacity 0.3s, transform 0.3s, filter 0.3s;
  &:hover {
    transform: scale(1.05);
    filter: brightness(1.2);
    opacity: 1;
  }
  @media (max-width: 48rem) {
    width: 8rem;
    height: 8rem;
  }
  @media (max-width: 450px) {
    width: 6rem;
    height: 6rem;
  }
  @media (max-width: 375px) {
    width: 5rem;
    height: 5rem;
  }
`;

const PartnerImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Statistics = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 2rem;
  border-top: 1px solid #e8e8e8;
  padding-top: 2rem;
  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  color: #333;
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
  strong {
    font-size: 2rem;
    font-weight: normal;
    color: #1b3a4b; /* Custom blue color */
    margin-bottom: 0.5rem;
  }
  span {
    color: #87ceeb; /* Custom green color */
  }
`;

const Subtitle = styled.h3`
  border: 1px solid #e8e8e8;
  color: #1ca3da;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  display: inline-block;
  font-weight: normal; /* Ensure the text is not bold */
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <Box gradient>
        <Subtitle>About Us</Subtitle>
        <BigTitle>
          Empowering success through <br />
          <em>innovation</em> and <em>tailored solutions</em>
        </BigTitle>
        <Description>
          At Tech Star Growth, we pride ourselves on being a dynamic hub of
          innovation and <br /> expertise in the realm of online services. With
          a steadfast commitment to excellence, we <br /> offer a diverse array
          of solutions tailored to meet the unique needs of our clients.
        </Description>
      </Box>
      <Image src={officeImage} alt='People working in an office' />
      <_Box>
        <Title>Join thousands of businesses trusting Tech Star Growth</Title>
        <Partners>
          <Partner>
            <PartnerImage src={partnerImage1} alt='Chain' />
          </Partner>
          <Partner>
            <PartnerImage src={partnerImage2} alt='EXEO' />
          </Partner>
          <Partner>
            <PartnerImage src={partnerImage3} alt='Natuska' />
          </Partner>
          <Partner>
            <PartnerImage src={partnerImage4} alt='Penta' />
          </Partner>
          <Partner>
            <PartnerImage src={partnerImage5} alt='Penta' />
          </Partner>
          <Partner>
            <PartnerImage src={partnerImage6} alt='Penta' />
          </Partner>
        </Partners>
        <Statistics>
          <Stat>
            <strong>15+</strong>
            <span>Years of Excellence</span>
          </Stat>
          <Stat>
            <strong>3K+</strong>
            <span>Employees</span>
          </Stat>
          <Stat>
            <strong>99.9%</strong>
            <span>Customer Satisfaction</span>
          </Stat>
          <Stat>
            <strong>50+</strong>
            <span>Industry Awards</span>
          </Stat>
        </Statistics>
      </_Box>
    </AboutUsContainer>
  );
};

export default withScrollAnimation(AboutUs);
