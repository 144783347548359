import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import withScrollAnimation from '../basics/withScrollAnimation';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.25rem;
  max-width: 1176px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  padding: 0.31rem 4rem;
  border: 2px solid #87ceeb;
  color: black;
  border-radius: 0.9375rem;
  @media (max-width: 48rem) {
    font-size: 1.1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.8em;
  margin-bottom: 2.5rem;
  text-align: center;
  @media (max-width: 48rem) {
    white-space: normal;
  }
`;

const FormContainer = styled.div`
  background-color: white;
  border-radius: 0.625rem;
  padding: 1.875rem;
  box-shadow: 0 0 1.25rem rgba(30, 144, 255, 0.4);
  max-width: 800px;
  width: 100%;
  @media (max-width: 48rem) {
    font-size: 1.1rem;
    width: 85%;
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;

  & > div {
    flex: 1;
    margin: 0 0.625rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.3125rem;
  display: block;
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const BoldLabel = styled(Label)`
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  max-width: 366px;
  padding: 0.625rem;
  font-size: 1em;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ccc')};
  border-radius: 0.3125rem;
  outline: none;

  &:focus {
    border-color: #1e90ff;
  }
  @media (max-width: 48rem) {
    padding: 0.625rem 0 0.625rem 0.3rem;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 0.875em;
  margin: 0.5rem 0 0 0;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;

const RadioOptions = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 1.25rem;
  }
`;
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; /* Adjust as needed for spacing between items */
    padding-top: 1rem;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 0.5rem; /* Adjust as needed for spacing between checkbox and text */
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const CheckboxInput = styled.input`
  margin-right: 0.5rem; /* Adjust as needed for spacing between checkbox and text */
  background-color: #ededed;
  padding: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const RequiredAsterisk = styled.span`
  color: red;
`;

const AlertBox = styled.div`
  /* width: 100%; */
  padding: 1rem;
  margin-bottom: 1.25rem;
  border-radius: 0.375rem;
  text-align: center;
  background-color: ${({ type }) =>
    type === 'success' ? '#d4edda' : type === 'error' ? '#f8d7da' : '#cce5ff'};
  color: ${({ type }) =>
    type === 'success' ? '#155724' : type === 'error' ? '#721c24' : '#004085'};
`;

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    city: '',
    qualification: '',
    gender: '',
    salary: '',
    fields: [],
  });

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const alertRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        fields: checked
          ? [...prevData.fields, value]
          : prevData.fields.filter((field) => field !== value),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName)
      newErrors.firstName = 'Your first name is required.';
    if (!formData.lastName) newErrors.lastName = 'Your last name is required.';
    if (!formData.email) newErrors.email = 'Your email is required.';
    if (!formData.password) newErrors.password = 'Your password is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ type: '', message: '' });
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      window.scrollTo({ top: alertRef.current.offsetTop, behavior: 'smooth' });
      return;
    }

    setIsSubmitting(true);
    window.scrollTo({ top: alertRef.current.offsetTop, behavior: 'smooth' });

    const submitData = {
      ...formData,
      phone: formData.phone || null,
      city: formData.city || null,
      qualification: formData.qualification || null,
      gender: formData.gender || null,
      salary: formData.salary || null,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MEGA_SERVER}/api/auth/register`,
        submitData,
      );
      setAlert({
        type: 'success',
        message:
          'Registration successful! Now contact your representative for further approval procedures.',
      });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        city: '',
        qualification: '',
        gender: '',
        salary: '',
        fields: [],
      });
    } catch (error) {
      console.error('Error during registration:', error);
      window.scrollTo({ top: alertRef.current.offsetTop, behavior: 'smooth' });

      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error
      ) {
        const duplicateEmailError =
          error.response.data.error === 'Email already exists';
        if (duplicateEmailError) {
          setAlert({
            type: 'error',
            message:
              'That email address has already been used. Please check your inbox for an activation email. It will become available in a couple of days if you do nothing.',
          });
        } else {
          setAlert({ type: 'error', message: 'Registration failed.' });
        }
      } else {
        setAlert({ type: 'error', message: 'Registration failed.' });
      }
    } finally {
      setIsSubmitting(false);
      window.scrollTo({ top: alertRef.current.offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <RegisterContainer>
      <Title>Register Yourself Now</Title>
      <Description>
        Ready to take the next step towards unlocking your full potential with
        Tech Star Growth? Register <br /> yourself now to access our wide range
        of services and start your journey towards success.
      </Description>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <div ref={alertRef}>
            {alert.message && (
              <AlertBox type={alert.type}>{alert.message}</AlertBox>
            )}
            {isSubmitting && <AlertBox type='info'>Submitting...</AlertBox>}
          </div>
          <FormRow>
            <div>
              <Label htmlFor='firstName'>
                First Name <RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type='text'
                id='firstName'
                name='firstName'
                value={formData.firstName}
                onChange={handleChange}
                isInvalid={errors.firstName}
              />
              {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
            </div>
            <div>
              <Label htmlFor='lastName'>
                Last Name <RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type='text'
                id='lastName'
                name='lastName'
                value={formData.lastName}
                onChange={handleChange}
                isInvalid={errors.lastName}
              />
              {errors.lastName && <ErrorText>{errors.lastName}</ErrorText>}
            </div>
          </FormRow>
          <FormRow>
            <div>
              <Label htmlFor='email'>
                Email <RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                isInvalid={errors.email}
              />
              {errors.email && <ErrorText>{errors.email}</ErrorText>}
            </div>
          </FormRow>
          <FormRow>
            <div>
              <Label htmlFor='password'>
                Password <RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type='password'
                id='password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                isInvalid={errors.password}
              />
              {errors.password && <ErrorText>{errors.password}</ErrorText>}
            </div>
          </FormRow>
          <FormRow>
            <div>
              <Label htmlFor='phone'>Phone</Label>
              <Input
                type='text'
                id='phone'
                name='phone'
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </FormRow>
          <FormRow>
            <div>
              <Label htmlFor='city'>City</Label>
              <Input
                type='text'
                id='city'
                name='city'
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div>
              <Label htmlFor='qualification'>Qualification</Label>
              <Input
                type='text'
                id='qualification'
                name='qualification'
                value={formData.qualification}
                onChange={handleChange}
              />
            </div>
          </FormRow>
          <RadioGroup>
            <BoldLabel>Gender</BoldLabel>
            <RadioOptions>
              <div>
                <Input
                  type='radio'
                  id='male'
                  name='gender'
                  value='male'
                  checked={formData.gender === 'male'}
                  onChange={handleChange}
                />
                <Label htmlFor='male'>Male</Label>
              </div>
              <div>
                <Input
                  type='radio'
                  id='female'
                  name='gender'
                  value='female'
                  checked={formData.gender === 'female'}
                  onChange={handleChange}
                />
                <Label htmlFor='female'>Female</Label>
              </div>
              <div>
                <Input
                  type='radio'
                  id='others'
                  name='gender'
                  value='others'
                  checked={formData.gender === 'others'}
                  onChange={handleChange}
                />
                <Label htmlFor='others'>Others</Label>
              </div>
            </RadioOptions>
          </RadioGroup>
          <RadioGroup>
            <BoldLabel>Salary</BoldLabel>
            <RadioOptions>
              <div>
                <Input
                  type='radio'
                  id='weekly'
                  name='salary'
                  value='weekly'
                  checked={formData.salary === 'weekly'}
                  onChange={handleChange}
                />
                <Label htmlFor='weekly'>Weekly</Label>
              </div>
              <div>
                <Input
                  type='radio'
                  id='monthly'
                  name='salary'
                  value='monthly'
                  checked={formData.salary === 'monthly'}
                  onChange={handleChange}
                />
                <Label htmlFor='monthly'>Monthly</Label>
              </div>
            </RadioOptions>
          </RadioGroup>
          <CheckboxGroup>
            <BoldLabel>Fields</BoldLabel>
            <div>
              <CheckboxInput
                type='checkbox'
                id='assignment'
                name='fields'
                value='assignment'
                checked={formData.fields.includes('assignment')}
                onChange={handleChange}
              />
              <CheckboxLabel htmlFor='assignment'>
                Assignment Writing
              </CheckboxLabel>
            </div>
            <div>
              <CheckboxInput
                type='checkbox'
                id='manualTyping'
                name='fields'
                value='manualTyping'
                checked={formData.fields.includes('manualTyping')}
                onChange={handleChange}
              />
              <CheckboxLabel htmlFor='manualTyping'>
                Manual Typing
              </CheckboxLabel>
            </div>
            <div>
              <CheckboxInput
                type='checkbox'
                id='linkSharing'
                name='fields'
                value='linkSharing'
                checked={formData.fields.includes('linkSharing')}
                onChange={handleChange}
              />
              <CheckboxLabel htmlFor='linkSharing'>Link Sharing</CheckboxLabel>
            </div>
            <div>
              <CheckboxInput
                type='checkbox'
                id='contentWriting'
                name='fields'
                value='contentWriting'
                checked={formData.fields.includes('contentWriting')}
                onChange={handleChange}
              />
              <CheckboxLabel htmlFor='contentWriting'>
                Content Writing
              </CheckboxLabel>
            </div>
            <div>
              <CheckboxInput
                type='checkbox'
                id='dataEntry'
                name='fields'
                value='dataEntry'
                checked={formData.fields.includes('dataEntry')}
                onChange={handleChange}
              />
              <CheckboxLabel htmlFor='dataEntry'>Data Entry</CheckboxLabel>
            </div>
            <div>
              <CheckboxInput
                type='checkbox'
                id='ads posting'
                name='fields'
                value='ads posting'
                checked={formData.fields.includes('ads posting')}
                onChange={handleChange}
              />
              <CheckboxLabel htmlFor='ads posting'>Ads posting</CheckboxLabel>
            </div>
          </CheckboxGroup>

          <SubmitButton type='submit'>Register</SubmitButton>
        </form>
      </FormContainer>
    </RegisterContainer>
  );
};

export default withScrollAnimation(RegisterPage);
