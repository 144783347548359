// withScrollAnimation.js
import React from 'react';
import styled, { css } from 'styled-components';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';
import fadeInUp from './FadeInUp';

const AnimatedContainer = styled.div`
  ${({ hasAnimated }) =>
    hasAnimated &&
    css`
      animation: ${fadeInUp} 2s ease forwards;
    `}
  opacity: ${({ hasAnimated }) => (hasAnimated ? 1 : 0)};
`;

const withScrollAnimation = (WrappedComponent) => {
  return (props) => {
    const [sectionRef, hasAnimated] = useAnimateOnScroll();

    return (
      <AnimatedContainer ref={sectionRef} hasAnimated={hasAnimated}>
        <WrappedComponent {...props} />
      </AnimatedContainer>
    );
  };
};

export default withScrollAnimation;
