import React from 'react';
import styled, { keyframes } from 'styled-components';
import isoImage from '../assets/recognize1_home.png'; // replace with actual path
import nistImage from '../assets/recognize2_home.png'; // replace with actual path
import gdprImage from '../assets/recognize3_home.png'; // replace with actual path
import withScrollAnimation from './withScrollAnimation';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const RecognizedSectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 20px;
  background: linear-gradient(
    90deg,
    rgba(30, 144, 255, 0.1) 0%,
    rgba(30, 144, 255, 0.5) 100%
  );
  background-size: 200% 200%;
  animation: ${gradientAnimation} 6s ease infinite;
  max-width: 1176px;
  margin: 0 auto;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 10px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  text-align: left;
padding: 2rem;
  @media (max-width: 768px) {
    text-align: center;
    /* order: 2; */
    margin-top: 20px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
`;

const Description = styled.p`
  font-size: 1.2em;
  line-height: 1.8em;
  margin-bottom: 40px;
  color: #333;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const StandardsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  /* padding: 2rem 2rem 2rem 2rem; */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    order: 1;
  }
`;

const Standard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const StandardImage = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
`;

const StandardText = styled.p`
  font-size: 1em;
  color: #333;
  text-align: center;
`;

const RecognizedSection = () => {
  return (
    <RecognizedSectionContainer>
      <TextContainer>
        <Title>Recognized by</Title>
        <Subtitle>global standards</Subtitle>
        <Description>
          Tech Star Growth is proud to be recognized by global standards for our
          unwavering commitment to excellence.
        </Description>
      </TextContainer>
      <StandardsContainer>
        <Standard>
          <StandardImage src={isoImage} alt='ISO 27001' />
          <StandardText>ISO/IEC 27001</StandardText>
        </Standard>
        <Standard>
          <StandardImage src={nistImage} alt='NIST' />
          <StandardText>NIST</StandardText>
        </Standard>
        <Standard>
          <StandardImage src={gdprImage} alt='GDPR Ready' />
          <StandardText>GDPR Ready</StandardText>
        </Standard>
      </StandardsContainer>
    </RecognizedSectionContainer>
  );
};

export default withScrollAnimation(RecognizedSection);
