import React from 'react';
import styled from 'styled-components';
import partnerImage1 from '../assets/parteners/chain.png';
import partnerImage2 from '../assets/parteners/exeo.png';
import partnerImage3 from '../assets/parteners/natuska.png';
import partnerImage4 from '../assets/parteners/network.png';
import partnerImage5 from '../assets/parteners/saona.png';
import partnerImage6 from '../assets/parteners/vision.png';
import withScrollAnimation from './withScrollAnimation';

const _Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin-bottom: 0;
    gap: 0;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Partner = styled.div`
  width: 10rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  opacity: 0.3;
  transition: opacity 0.3s, transform 0.3s, filter 0.3s;
  &:hover {
    transform: scale(1.05);
    filter: brightness(1.2);
    opacity: 1;
  }
  @media (max-width: 48rem) {
    width: 8rem;
    height: 8rem;
  }
  @media (max-width: 450px) {
    width: 6rem;
    height: 6rem;
  }
`;

const PartnerImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Partners = () => {
  return (
    <_Partners>
      <Partner>
        <PartnerImage src={partnerImage1} alt='Chain' />
      </Partner>
      <Partner>
        <PartnerImage src={partnerImage2} alt='EXEO' />
      </Partner>
      <Partner>
        <PartnerImage src={partnerImage3} alt='Natuska' />
      </Partner>
      <Partner>
        <PartnerImage src={partnerImage4} alt='Penta' />
      </Partner>
      <Partner>
        <PartnerImage src={partnerImage5} alt='Penta' />
      </Partner>
      <Partner>
        <PartnerImage src={partnerImage6} alt='Penta' />
      </Partner>
    </_Partners>
  );
};

export default withScrollAnimation(Partners);
