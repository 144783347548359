import React from 'react';
import styled from 'styled-components';
import fadeInUp from './FadeInUp';
import withScrollAnimation from './withScrollAnimation';

const ServicesSectionContainer = styled.section`
  padding: 3.125rem 1.25rem;
  text-align: center;
  background-color: white;
  animation: ${fadeInUp} 1s ease forwards;
`;

const Title = styled.h2`
  color: #1b3a4b;
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
  animation: ${fadeInUp} 1s ease forwards;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 450x) {
    font-size: 1.1rem;
  }
`;

const Subtitle = styled.p`
  color: #1b3a4b;
  font-size: 1rem;
  margin-bottom: 2.5rem;
  animation: ${fadeInUp} 1s ease forwards;

  @media (max-width: 450x) {
    font-size: 0.9rem;
  }
`;

const ServicesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  animation: ${fadeInUp} 1s ease forwards;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ServiceCard = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  padding: ${(props) => (props.large ? '0' : '1.25rem')};
  text-align: left;
  margin: 1.25rem;
  flex: ${(props) =>
    props.large ? '2' : '1'}; // Adjusting the size of the second card
  max-width: ${(props) => (props.large ? '31.25rem' : '18.75rem')};
  animation: ${fadeInUp} 1s ease forwards;

  img {
    width: 100%;
    border-radius: 0.625rem;
    margin: 0;
    animation: ${fadeInUp} 1s ease forwards;
  }

  h3 {
    color: #1b3a4b;
    font-size: 1.25rem;
    margin: 1.25rem 0 0.625rem;
    animation: ${fadeInUp} 1s ease forwards;
  }

  p {
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
    animation: ${fadeInUp} 1s ease forwards;
    @media (max-width: 450px) {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 1rem 0;
  }
`;

const Text = styled.div`
  padding: 1.25rem;
`;

const ServicesSection = () => {
  return (
    <ServicesSectionContainer id='services'>
      <Title>
        Customized solutions for <br /> diverse online needs
      </Title>
      <Subtitle>
        Our commitment extends beyond mere project completion; we strive to make
        a <br />
        meaningful impact, fostering positive change in the world.
      </Subtitle>
      <ServicesContainer>
        <ServiceCard>
          <img src={require('../assets/services_home1.png')} alt='Service 1' />
          <h3>Commitment to Sustainability</h3>
          <p>
            Sustainability is more than just a buzzword – it's a core value that
            guides everything we do.
          </p>
        </ServiceCard>
        <ServiceCard large>
          <img src={require('../assets/services_home2.png')} alt='Service 2' />
          <Text>
            <h3>Our Company Culture: Fostering Excellence</h3>
            <p>
              At Tech Star Growth, our company culture is built on a foundation
              of excellence, collaboration, and innovation.
            </p>
          </Text>
        </ServiceCard>
        <ServiceCard>
          <img src={require('../assets/services_home3.png')} alt='Service 3' />
          <h3>Your Success is Our Priority</h3>
          <p>
            We're dedicated to understanding your needs and providing
            personalized solutions that drive success.
          </p>
        </ServiceCard>
      </ServicesContainer>
    </ServicesSectionContainer>
  );
};

export default withScrollAnimation(ServicesSection);
