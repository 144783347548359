import React from 'react';
import styled from 'styled-components';
import placeholderImage from '../assets/presidentMessage_aboutUs.png'; // Replace with your actual image path
import withScrollAnimation from '../basics/withScrollAnimation';

const PresidentMessageContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3rem 1rem;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
    padding: 0 0.625rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 2rem;
  @media (max-width: 48rem) {
    padding-right: 0;
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1b3a4b; /* Custom blue color */
  margin-bottom: 1rem;
  @media (max-width: 48rem) {
    text-align: center;
  }
  @media (max-width: 450px) {
    font-size: 1.2rem;
  }
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
  @media (max-width: 48rem) {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 450px) {
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 30rem;
    height: 30rem;
    border-radius: 0.625rem;
    @media (max-width: 48rem) {
      width: 23rem;
      height: 23rem;
    }
    @media (max-width: 450px) {
      width: 22rem;
      height: 22rem;
    }
    @media (max-width: 380px) {
      width: 20rem;
      height: 20rem;
    }
  }
`;

const PresidentMessage = () => {
  return (
    <PresidentMessageContainer>
      <TextContainer>
        <Title>President Message</Title>
        <Text>
          As the President of Tech Star Growth, it is my privilege to lead a
          team of dedicated professionals who are deeply committed to delivering
          excellence in all aspects of our work. At Tech Star Growth, we focus
          on providing innovative solutions that drive success for our clients
          and make a positive societal impact.
        </Text>
        <Text>
          With our extensive expertise in various services, including assignment
          writing, data entry, CSR initiatives, and digital marketing, we
          consistently strive to exceed expectations and deliver measurable
          results.
        </Text>
        <Text>
          As we continue to grow and evolve, we remain steadfast in our
          commitment to integrity, innovation, and customer satisfaction. Thank
          you for choosing Tech Star Growth as your trusted partner. Together,
          let's shape a brighter future.
        </Text>
        <Text>
          Mr Tech,
          <br />
          CEO, Tech Star Growth.
        </Text>
      </TextContainer>
      <ImageContainer>
        <img
          src={placeholderImage}
          alt='Businessman writing on clipboard with colleagues in the background'
        />
      </ImageContainer>
    </PresidentMessageContainer>
  );
};

export default withScrollAnimation(PresidentMessage);
