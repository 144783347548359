import React from 'react';
import styled from 'styled-components';
import innovationImage from '../assets/values/innovation.png'; // Replace with your actual image path
import integrityImage from '../assets/values/Integrity.png'; // Replace with your actual image path
import userCentricImage from '../assets/values/user_centric.png'; // Replace with your actual image path
import withScrollAnimation from '../basics/withScrollAnimation';

const ValuesContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 2rem 8rem 2rem;
  border-radius: 0.625rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 5rem;
  margin: 0 auto;
  /* background: ${(props) =>
    props.gradient
      ? 'linear-gradient(120deg, rgba(0, 0, 255, 0.1) 0%, rgba(255, 255, 255, 0.9) 100%)'
      : 'white'}; */
  background: linear-gradient(
    359deg,
    rgb(108 108 110 / 3%) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  position: relative;
  max-width: 68.5rem;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 91%;
    height: 7rem;
    background: radial-gradient(circle, #e8e8e8 1px, transparent 1px);
    background-size: 10px 10px;
  }
  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    max-width: 19rem;
  }
`;
const TextContainer = styled.div`
  width: 50%;
  padding-right: 2rem;
  @media (max-width: 48rem) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  border: 1px solid #87ceeb;
  color: #87ceeb;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #1b3a4b; /* Custom blue color */
  text-align: left;
  margin: 1rem 0;
  @media (max-width: 48rem) {
    font-size: 1.2rem;
    text-align: center;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: left;
  color: #333;
  @media (max-width: 48rem) {
    font-size: 1rem;
    text-align: center;
  }
`;

const ValuesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
  @media (max-width: 48rem) {
    width: 100%;
  }
`;

const ValueItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #b1b1b138;
  border-radius: 0.625rem;
  max-width: 29rem;
  @media (max-width: 48rem) {
    max-width: 19.3rem;
    align-items: center;
  }
`;

const ValueIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const ValueText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueTitle = styled.h4`
  font-size: 1rem;
  margin: 0;
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  margin: 0.5rem 0 0 0;
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const Dots = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2rem;
  background: radial-gradient(#b1b1b1 20%, transparent 20%) repeat-x;
  background-size: 10px 10px;
`;

const Values = () => {
  return (
    <ValuesContainer>
      <TextContainer>
        <Subtitle>Our values</Subtitle>
        <Title>Our core values are the foundation of our success</Title>
        <Description>
          At Tech Star Growth, our values form the cornerstone of everything we
          do.
        </Description>
      </TextContainer>
      <ValuesList>
        <ValueItem>
          <ValueIcon src={innovationImage} alt='Innovation' />
          <ValueText>
            <ValueTitle>Innovation</ValueTitle>
            <ValueDescription>
              We constantly seek out new ideas and deliver cutting-edge
              solutions that surpass expectations.
            </ValueDescription>
          </ValueText>
        </ValueItem>
        <ValueItem>
          <ValueIcon src={integrityImage} alt='Integrity' />
          <ValueText>
            <ValueTitle>Integrity</ValueTitle>
            <ValueDescription>
              We uphold the highest standards of integrity, earning the trust
              and respect of those we serve.
            </ValueDescription>
          </ValueText>
        </ValueItem>
        <ValueItem>
          <ValueIcon src={userCentricImage} alt='User-centric' />
          <ValueText>
            <ValueTitle>User-centric</ValueTitle>
            <ValueDescription>
              At the heart of our operations lies a deep commitment to
              understanding and meeting the needs of our users.
            </ValueDescription>
          </ValueText>
        </ValueItem>
      </ValuesList>
      <Dots />
    </ValuesContainer>
  );
};

export default withScrollAnimation(Values);
