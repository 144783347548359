import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router';
import withScrollAnimation from './withScrollAnimation';

const HeroSectionContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.25rem;
  max-width: 85%;
  margin-left: auto;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(to right, white, #94bbc7);
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  max-width: 31.25rem;
  padding: 1.25rem;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: normal;
  margin-bottom: 1.25rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.6rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 1.25rem;
  line-height: 1.8rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.625rem;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#87ceeb' : 'white')};
  color: ${(props) => (props.primary ? 'white' : '#1B3A4B')};
  border: none;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${(props) => (props.primary ? '' : '#87ceeb')};
    color: ${(props) => (props.primary ? '' : 'white')};
  }

  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1.25rem);
  }
  100% {
    transform: translateY(0);
  }
`;

const LogoContainer = styled.div`
  margin-top: 2rem;
  animation: ${float} 1.5s ease-in-out infinite;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 14rem;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  height: 15rem;
  @media (max-width: 768px) {
    max-width: 100%;
    height: 10rem;
  }
  @media (max-width: 450px) {
    /* max-width: 100%; */
    height: 8rem;
  }
`;

const SiteName = styled.p`
  margin-top: 1rem;
  font-size: 2rem;
  /* font-weight: bold; */
  color: #1b3a4b;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 18rem;
  background: radial-gradient(circle, rgba(173, 216, 230, 0.3), transparent);
  border-radius: 50%;
  position: relative;
  @media (max-width: 768px) {
    width: 10rem;
    height: 10rem;
  }
  @media (max-width: 450px) {
    width: 10rem;
    height: 10rem;
  }
`;

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <HeroSectionContainer>
      <TextContainer>
        <Title>
          Unlocking <em>Opportunities </em>through diverse online projects.
        </Title>
        <Description>
          Welcome to TechStarGrowth, your premier destination for comprehensive
          online project solutions. With a commitment to excellence and
          innovation, we strive to unlock new opportunities for individuals and
          businesses alike.
        </Description>
        <ButtonContainer>
          <Button primary onClick={() => navigate('/register')}>
            Get started
          </Button>
          <Button onClick={() => navigate('/about')}>Learn more</Button>
        </ButtonContainer>
      </TextContainer>
      <LogoContainer>
        <LogoWrapper>
          <Logo src={require('../assets/logo.png')} alt='TechStarGrowth Logo' />
        </LogoWrapper>
        <SiteName>ᴛᴇᴄʜ ꜱᴛᴀʀ ɢʀᴏᴡᴛʜ</SiteName>
      </LogoContainer>
    </HeroSectionContainer>
  );
};

export default withScrollAnimation(HeroSection);
