import React from 'react';
import styled from 'styled-components';
import withScrollAnimation from '../basics/withScrollAnimation';

const JourneyContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 33.5rem; /* 536px in rem */
  margin: 0 auto;
  @media (max-width: 48rem) {
    padding: 1.875rem 0.625rem;
  }
`;

const Title = styled.h2`
  font-size: 1.7rem;
  font-weight: normal;
  color: #1b3a4b; /* Custom blue color */
  text-align: center;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 300;
  font-style: italic;
  color: #1b3a4b; /* Custom blue color */
  text-align: center;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  color: #333;
  margin: 1rem 0; /* Add specific margin if needed */
  padding: 0; /* Remove padding */
  max-width: 60rem;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin: 1.5rem 0;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    margin: 1.5rem 0;
  }
`;

const Journey = () => {
  return (
    <JourneyContainer>
      <Subtitle>Our Journey</Subtitle>
      <Text>
        Our journey at Tech Star Growth began with a vision to redefine the
        standards of online project solutions. From our humble beginnings, we
        have embarked on a path of continuous growth and innovation. With each
        project undertaken, we have honed our skills, expanded our expertise,
        and forged lasting partnerships with clients around the globe.
      </Text>
      <Title>Our Vision</Title>
      <Text>
        At Tech Star Growth, our vision is to be at the forefront of shaping the
        future of online project solutions. We aspire to be recognized globally
        for our innovation, expertise, and commitment to excellence in every
        endeavor. With a focus on harnessing the power of technology and human
        ingenuity, we envision a world where individuals and businesses alike
        can realize their full potential with seamless, tailored online
        solutions.
      </Text>
    </JourneyContainer>
  );
};

export default withScrollAnimation(Journey);
