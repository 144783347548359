import React from 'react';
import styled from 'styled-components';
import withScrollAnimation from './withScrollAnimation';
import useResponsiveScreen from '../hooks/useResponsiveScreen';

const IntegrationsSectionContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  max-width: 1176px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const Button = styled.button`
  background-color: #87ceeb; /* Your custom blue color */
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
  @media (max-width: 450px) {
    font-size: 0.8rem;
    padding: 7px 15px;
  }
`;

const SmallButton = styled.button`
  background-color: #87ceeb; /* Your custom blue color */
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 0.8em;
  cursor: pointer;
  margin-bottom: 20px;
  pointer-events: none;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
  @media (max-width: 450px) {
    margin-right: 0;
  }
`;

const IntegrationImage = styled.img`
  width: 25rem;
  height: 25rem;
  @media (max-width: 768px) {
    width: 24rem;
    height: 24rem;
  }
  @media (max-width: 450px) {
    width: 22rem;
    height: 22rem;
  }
  @media (max-width: 375px) {
    width: 21rem;
    height: 21rem;
  }
`;

const IntegrationsSection = () => {
    const { isMobile } = useResponsiveScreen();

  return (
    <IntegrationsSectionContainer>
      <ImageContainer>
        <IntegrationImage
          src={require('../assets/integrationSection_home.png')}
          alt='Integration Services'
        />
      </ImageContainer>
      <TextContainer>
        <SmallButton>Integrations</SmallButton>
        <Title>
          Seamless {!isMobile ? <br /> : ''} Integration:
          {!isMobile ? <br /> : ''} Enhancing Your Online Experience
        </Title>
        <Description>
          We understand the importance of cohesive systems and processes to
          enhance your online experience.
        </Description>
        <Button>Contact Us</Button>
      </TextContainer>
    </IntegrationsSectionContainer>
  );
};

export default withScrollAnimation(IntegrationsSection);
