import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import withScrollAnimation from '../basics/withScrollAnimation';

const ContactPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 1rem;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  margin-top: 8rem;
  position: relative;

  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactInfo = styled.div`
  flex: 1;
  padding-right: 10rem;
  @media (max-width: 48rem) {
    padding-right: 0;
    text-align: center;
    margin-top: 18rem;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  max-width: 26rem;
  background: white;
  padding: 2rem;
  border-radius: 0.625rem;
  border: 1px solid #ddd;
  @media (max-width: 48rem) {
    margin-top: 2rem;
    padding: 1rem;
    height: 100%;
  }
`;

const Title = styled.h2`
  font-size: 1rem;
  font-weight: normal; /* Ensure the text is not bold */
  margin-bottom: 1rem;
  color: black; /* Custom blue color */
  @media (max-width: 48rem) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 1.1rem;
  }
`;

const _Title = styled.h2`
  font-size: 4rem;
  font-weight: normal; /* Ensure the text is not bold */
  margin-bottom: 1rem;
  color: black; /* Custom blue color */
  @media (max-width: 48rem) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.h3`
  border: 1px solid #e8e8e8;
  color: #1ca3da;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  display: inline-block;
  font-weight: normal; /* Ensure the text is not bold */
  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
  @media (max-width: 48rem) {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: black;
`;

const ContactDetail = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.5rem 0;
  @media (max-width: 48rem) {
    font-size: 0.875rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
  position: relative;
  @media (max-width: 48rem) {
    font-size: 0.875rem;
  }
`;

const RequiredAsterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ddd')};
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #ededed;
  transition: border-color 0.3s;
  &:focus {
    border-color: #87ceeb;
    outline: none;
  }
  @media (max-width: 48rem) {
    padding: 0.625rem;
    font-size: 0.875rem;
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #ededed;
  resize: vertical; /* Only allow vertical resizing */
  transition: border-color 0.3s;
  &:focus {
    border-color: #87ceeb;
    outline: none;
  }
  @media (max-width: 48rem) {
    padding: 0.625rem;
    font-size: 0.875rem;
  }
`;

const SubmitButton = styled.button`
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  @media (max-width: 48rem) {
    padding: 0.625rem;
    font-size: 0.875rem;
  }
`;

const Wrapper = styled.div`
  background: linear-gradient(
    209deg,
    rgb(103 162 230 / 20%) 0%,
    rgb(255 255 255 / 10%) 70%,
    rgba(255, 255, 255, 0.9) 100%
  );
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 0.875rem;
  margin: -0.5rem 0 1rem 0;
`;

const AlertBox = styled.div`
  /* width: 100%; */
  font-size: 1rem;
  padding: 1rem;
  margin-bottom: 1.25rem;
  border-radius: 0.375rem;
  text-align: center;
  background-color: ${({ type }) =>
    type === 'success' ? '#d4edda' : type === 'error' ? '#f8d7da' : '#cce5ff'};
  color: ${({ type }) =>
    type === 'success' ? '#155724' : type === 'error' ? '#721c24' : '#004085'};
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required.';
    if (!formData.lastName) newErrors.lastName = 'Last name is required.';
    if (!formData.email) newErrors.email = 'Email is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MEGA_SERVER}/api/contact/contact`,
        formData,
      );
      setAlert({
        type: 'success',
        message: 'Contact form submitted successfully',
      });
    } catch (error) {
      setAlert({
        type: 'error',
        message:
          error.response?.data?.error ||
          'Error submitting contact form. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Wrapper>
      <ContactPageContainer>
        <ContactInfo>
          <Subtitle>Contact Us</Subtitle>
          <_Title>
            Don't hesitate to <br /> <em>reach</em> out to us.
          </_Title>
          <Description>
            We're here to assist you every step of the way. Whether you have
            questions about our services, need assistance with a project, or
            want to explore partnership opportunities, don't hesitate to reach
            out.
          </Description>
          <ContactDetails>
            <ContactDetail>📧 techstargrowth@gmail.com</ContactDetail>
            <ContactDetail>
              📍 MM ALAM Road Block C1 block T Gulberg, Lahore.
            </ContactDetail>
          </ContactDetails>
        </ContactInfo>
        <FormContainer>
          <Title>
            Have questions about our cybersecurity solutions or need assistance?
            Feel free to reach out to us.
          </Title>
          <Form onSubmit={handleSubmit}>
            {alert.message && (
              <AlertBox type={alert.type}>{alert.message}</AlertBox>
            )}
            {isSubmitting && <AlertBox type='info'>Submitting...</AlertBox>}
            <Label htmlFor='firstName'>
              First Name <RequiredAsterisk>*</RequiredAsterisk>
            </Label>
            <Input
              type='text'
              id='firstName'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              isInvalid={errors.firstName}
            />
            {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
            <Label htmlFor='lastName'>
              Last Name <RequiredAsterisk>*</RequiredAsterisk>
            </Label>
            <Input
              type='text'
              id='lastName'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              isInvalid={errors.lastName}
            />
            {errors.lastName && <ErrorText>{errors.lastName}</ErrorText>}
            <Label htmlFor='email'>
              Email Address <RequiredAsterisk>*</RequiredAsterisk>
            </Label>
            <Input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              isInvalid={errors.email}
            />
            {errors.email && <ErrorText>{errors.email}</ErrorText>}
            <Label htmlFor='phone'>Phone Number</Label>
            <Input
              type='tel'
              id='phone'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
            />
            <Label htmlFor='message'>Message</Label>
            <Textarea
              id='message'
              name='message'
              rows='4'
              maxLength='180'
              value={formData.message}
              onChange={handleChange}
            />
            <SubmitButton type='submit'>Send Message</SubmitButton>
          </Form>
        </FormContainer>
      </ContactPageContainer>
    </Wrapper>
  );
};

export default withScrollAnimation(ContactForm);
