import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import JoinSection from '../basics/JoinSection';
import withScrollAnimation from '../basics/withScrollAnimation';
import { useNavigate } from 'react-router-dom';

const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  padding: 1rem;
`;

const LoginForm = styled.form`
  padding: 2rem;
  border-radius: 0.625rem;
  width: 100%;
  max-width: 28rem; /* 448px in rem */
  @media (max-width: 48rem) {
    padding: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  @media (max-width: 48rem) {
    font-size: 1.25rem;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
  width: 100%;
  @media (max-width: 48rem) {
    font-size: 0.875rem;
  }
`;

const Input = styled.input`
  width: 95%;
  max-width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : '#ddd')};
  border-radius: 0.25rem;
  font-size: 1rem;
  @media (max-width: 48rem) {
    padding: 0.625rem;
    font-size: 0.875rem;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 0.875rem;
  margin: -0.5rem 0 1rem 0;
`;

const RememberMeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
  @media (max-width: 48rem) {
    margin-bottom: 1rem;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const LoginButton = styled.button`
  align-self: flex-start;
  width: 8rem;
  padding: 0.75rem;
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  @media (max-width: 48rem) {
    width: 100%;
    padding: 0.625rem;
    font-size: 0.875rem;
  }
`;

const PasswordLink = styled.a`
  display: block;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: #1e90ff;
  text-decoration: none;
  width: 100%;
  @media (max-width: 48rem) {
    font-size: 0.75rem;
  }
`;

const RequiredAsterisk = styled.span`
  color: red;
`;

const AlertBox = styled.div`
  padding: 1rem;
  margin-bottom: 1.25rem;
  border-radius: 0.375rem;
  text-align: center;
  background-color: ${({ type }) =>
    type === 'success' ? '#d4edda' : type === 'error' ? '#f8d7da' : '#cce5ff'};
  color: ${({ type }) =>
    type === 'success' ? '#155724' : type === 'error' ? '#721c24' : '#004085'};
`;

const isEmail = (input) => /\S+@\S+\.\S+/.test(input);

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username)
      newErrors.username = 'Your email or username is required.';
    if (!formData.password) newErrors.password = 'Your password is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    setIsSubmitting(true);
    try {
      const loginData = isEmail(formData.username)
        ? { email: formData.username, password: formData.password }
        : { username: formData.username, password: formData.password };
      const response = await axios.post(
        `${process.env.REACT_APP_MEGA_SERVER}/api/auth/login`,
        loginData,
      );
      console.log(response.data);
      localStorage.setItem('token', response.data.token);
      setAlert({ type: 'success', message: 'Login successful' });
      navigate('/admin')
      // handle success, e.g., redirect or store token
    } catch (error) {
      setAlert({
        type: 'error',
        message:
          error.response?.data?.error || 'Error logging in. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <LoginPageContainer>
        <LoginForm onSubmit={handleSubmit}>
          <Title>Employee Login</Title>
          {alert.message && (
            <AlertBox type={alert.type}>{alert.message}</AlertBox>
          )}
          {isSubmitting && <AlertBox type='info'>Submitting...</AlertBox>}
          <Label htmlFor='username' style={{ fontWeight: 'bold' }}>
            Username or Email Address <RequiredAsterisk>*</RequiredAsterisk>
          </Label>
          <Input
            type='text'
            id='username'
            name='username'
            value={formData.username}
            onChange={handleChange}
            isInvalid={errors.username}
          />
          {errors.username && <ErrorText>{errors.username}</ErrorText>}
          <Label htmlFor='password' style={{ fontWeight: 'bold' }}>
            Password <RequiredAsterisk>*</RequiredAsterisk>
          </Label>
          <Input
            type='password'
            id='password'
            name='password'
            value={formData.password}
            onChange={handleChange}
            isInvalid={errors.password}
          />
          {errors.password && <ErrorText>{errors.password}</ErrorText>}
          <PasswordLink href='#'>Lost your password?</PasswordLink>
          <RememberMeContainer>
            <CheckboxWrapper>
              <Checkbox type='checkbox' id='remember' name='remember' />
              <Label htmlFor='remember'>Remember Me</Label>
            </CheckboxWrapper>
          </RememberMeContainer>
          <LoginButton type='submit'>Login</LoginButton>
        </LoginForm>
      </LoginPageContainer>
      <JoinSection />
    </>
  );
};

export default withScrollAnimation(Login);
