import React from 'react';
import styled from 'styled-components';
import logoImage from '../assets/logo.png'; // Replace with your actual logo path
import withScrollAnimation from '../basics/withScrollAnimation';

const MissionContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem 1rem;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  @media (max-width: 48rem) {
    flex-direction: column;
    padding: 1.875rem 0.625rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  position: relative;
  @media (max-width: 48rem) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 18rem;
  background: radial-gradient(circle, rgba(173, 216, 230, 0.3), transparent);
  border-radius: 50%;
  position: relative;
`;

const Logo = styled.img`
  width: 10rem;
  height: 10rem;
  border: 5px solid #87ceeb;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
`;

const SiteName = styled.p`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #87ceeb;
  text-align: center;
`;

const LightBox = styled.div`
  width: 14rem;
  height: 14rem;
  background: rgba(173, 216, 230, 0.5);
  border-radius: 0.625rem;
  box-shadow: 0 30px 30px rgba(173, 216, 230, 0.7);
  margin-top: 1rem;
  transform: rotateX(60deg);
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotateX(60deg);
`;

const TextContainer = styled.div`
  width: 50%;
  padding-left: 2rem;
  @media (max-width: 48rem) {
    width: 100%;
    padding-left: 0;
    text-align: center; /* Center-align child elements on smaller screens */
  }
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  border: 1px solid #87ceeb;
  color: #87ceeb;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  display: inline-block;
  font-weight: normal;
  @media (max-width: 48rem) {
    font-size: 1rem;
    text-align: center; /* Center-align the text inside the element */
    margin: 0 auto; /* Center the element itself */
  }
  @media (max-width: 450px) {
    font-size: 0.8rem;
    text-align: center; /* Center-align the text inside the element */
    margin: 0 auto; /* Center the element itself */
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: normal;
  color: #1b3a4b; /* Custom blue color */
  text-align: left;
  margin: 1rem 0;
  @media (max-width: 48rem) {
    font-size: 2rem;
    text-align: center;
  }
  @media (max-width: 450px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: left;
  color: #333;
  @media (max-width: 48rem) {
    font-size: 1rem;
    text-align: center;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    text-align: center;
    line-height: 1.5;
  }
`;

const Mission = () => {
  return (
    <MissionContainer>
      <LogoContainer>
        <LogoWrapper>
          <Logo src={logoImage} alt='Tech Star Growth' />
        </LogoWrapper>
        <SiteName>Tech Star Growth</SiteName>
        <LightBox />
      </LogoContainer>
      <TextContainer>
        <Subtitle>Our mission</Subtitle>
        <Title>
          Driving excellence, <br /> fostering change,
          <br />
          <em>empowering</em> global
          <br /> progress.
        </Title>
        <Description>
          Our commitment extends beyond mere service provision; we aim to make a
          meaningful impact through our CSR initiatives, contributing to the
          betterment of communities worldwide. With integrity, dedication, and a
          relentless pursuit of excellence, we are on a mission to be the
          trusted partner of choice for all online project needs, enabling our
          clients to thrive in an ever-evolving digital landscape.
        </Description>
      </TextContainer>
    </MissionContainer>
  );
};

export default withScrollAnimation(Mission);
