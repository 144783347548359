import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // replace with your logo path
import withScrollAnimation from './withScrollAnimation';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const JoinSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background: linear-gradient(
      180deg,
      rgba(182, 220, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.1) 30%,
      rgba(255, 255, 255, 0.5) 100%
    ),
    radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: cover, 1rem 1rem;
  animation: ${gradientAnimation} 6s ease infinite;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  border-radius: 0.625rem;
  margin-top: 2.5rem;
  @media (max-width: 48rem) {
    padding: 1.875rem 0.625rem;
  }
  @media (max-width: 450px) {
    max-width: 380px;
  }
  @media (max-width: 375px) {
    max-width: 330px;
  }
  position: relative;
`;

const LogoImage = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 1.25rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  color: #1b3a4b; /* Custom blue color */
  text-align: center;

  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
  @media (max-width: 375px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 1.25rem;
  color: #1e90ff; /* Custom blue color */

  @media (max-width: 48rem) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 2.5rem;
  text-align: center;
  color: #333;

  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 1.875rem;
  }
  @media (max-width: 420px) {
    font-size: 0.9rem;
    margin-bottom: 1.875rem;
  }
`;

const GetStartedButton = styled(Link)`
  background-color: #1b3a4b; /* Custom blue color */
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  text-decoration: none;
  display: inline-block;
  margin-top: 1.25rem;

  @media (max-width: 48rem) {
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
  }
`;

const JoinSection = () => {
  return (
    <JoinSectionContainer>
      <LogoImage src={logo} alt='Tech Star Growth Logo' />
      <Title>
        Join our <italic>innovative</italic> team today.
      </Title>
      <Description>
        Join our innovative team at Tech Star Growth and be part of shaping the
        future of online solutions. We're seeking passionate individuals who
        thrive in dynamic environments and are committed to excellence.
      </Description>
      <GetStartedButton to='/register'>Get Started</GetStartedButton>
    </JoinSectionContainer>
  );
};

export default withScrollAnimation(JoinSection);
