import React from 'react';
import styled, { keyframes } from 'styled-components';
import projectManagementImage from '../assets/project_management_services.png'; // replace with your image path
import withScrollAnimation from './withScrollAnimation';

const gradientAnimation = keyframes`
  0% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 10% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  max-width: 73.5rem; /* 1176px in rem */
  margin: 0 auto;
  border-radius: 0.625rem;
  margin-top: 2.5rem;
  @media (max-width: 48rem) {
    padding: 1.875rem 0.625rem;
  }
  position: relative;
`;

const Dots = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px
  );
  background-size: 1rem 1rem;
  pointer-events: none;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(182, 220, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.1) 30%,
    rgba(255, 255, 255, 0.5) 100%
  );
  background-size: 200% 200%;
  animation: ${gradientAnimation} 6s ease infinite;
  padding: 3rem 1rem;
  border-radius: 0.625rem 0.625rem 0 0;
  position: relative;
  animation: ${fadeInUp} 1s ease forwards;
  width: 100%;
`;

const CategoryBadge = styled.div`
  color: #41a4cc;
  border: 1px solid #41a4cc;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  color: black; /* Custom blue color */
  text-align: center;
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 2.5rem;
  text-align: center;
  color: #333;
  @media (max-width: 48rem) {
    font-size: 1rem;
    margin-bottom: 1.875rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const ImageContainer = styled.div`
  width: 28rem;
  margin-top: -1rem; /* Adjust the margin to overlap the gradient */
  animation: ${fadeInUp} 1s ease forwards;

  @media (max-width: 48rem) {
    width: 100%;
    animation: ${fadeInUp} 1s ease forwards;
  }
  img {
    width: 100%;
    border-radius: 0 0 0.625rem 0.625rem;
  }
`;

const ProjectManagementSection = () => {
  return (
    <SectionContainer>
      <Dots />
      <ContentWrapper>
        <CategoryBadge>Project Management</CategoryBadge>
        <Title>Ensuring Success Every Step of the Way</Title>
        <Description>
          Effective project management is the backbone of successful endeavors,
          and at Tech Star Growth, we excel in guiding projects from
          conception to completion. Our experienced project managers leverage
          industry-leading methodologies and best practices to ensure that
          projects are delivered on time, within budget, and to the highest
          quality standards.
        </Description>
      </ContentWrapper>
      <ImageContainer>
        <img src={projectManagementImage} alt='Project Management' />
      </ImageContainer>
    </SectionContainer>
  );
};

export default withScrollAnimation(ProjectManagementSection);
