// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import HomePage from './components/HomePage';
import Services from './pages/Services';
import About from './pages/AboutUs';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Register from './pages/Register';
import Login from './pages/Login';
import { ScrollToTop } from './utilities';
import useAnimateOnScroll from './hooks/useAnimateOnScroll';
import AdminPage from './pages/AdminPage';

const Container = styled.div`
  background-color: white;
  color: #1b3a4b;
  font-family: Arial, sans-serif;
  /* max-width: 1176px; */
  margin: 0 auto;
`;

const App = () => {
  const [sectionRef, hasAnimated] = useAnimateOnScroll();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
    const token = localStorage.getItem('token');
  //   if (token) {
  //     setIsLoggedIn(true);
  //   }
  // }, []);
  return (
    <Router>
      <Container ref={sectionRef} hasAnimated={hasAnimated}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          {token ?? <Route path='/admin' element={<AdminPage />} />}
          <Route path='/admin' element={<AdminPage />} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
};

export default App;
