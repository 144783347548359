import React from 'react';
import styled from 'styled-components';
import sampleImage from '../assets/choosePartener_services.png'; // replace with your image path
import withScrollAnimation from './withScrollAnimation';

const ServicesSectionContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 3rem 1rem;
  max-width: 63.5rem; /* 1176px in rem */
  margin: 0 auto;
  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.875rem 0.625rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-left: 2rem;
  @media (max-width: 48rem) {
    padding-left: 0;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 24rem;
    height: 24rem;
    border-radius: 0.625rem;
    @media (max-width: 450px) {
      width: 22rem;
      height: 22rem;
    }
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
  margin-bottom: 0.5rem;
  color: black;
  @media (max-width: 48rem) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 300;
  font-style: italic;
  margin: 0;
  margin-bottom: 0.5rem;
  color: black;
  @media (max-width: 48rem) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #333;
  margin-top: 1rem; /* Add a margin top to create space between subtitle and description */
  @media (max-width: 48rem) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

const ChooseParteners = () => {
  return (
    <ServicesSectionContainer>
      {/* <FadeInUpWrapper> */}
      <ImageContainer>
        <img src={sampleImage} alt='Services' />
      </ImageContainer>
      {/* </FadeInUpWrapper> */}
      {/* <FadeInUpWrapper> */}
      <TextContainer>
        <Title>Why Choose Us:</Title>
        <Subtitle>
          Your Partner for <br />
          Success
        </Subtitle>
        <Description>
          We understand that every project is unique. That’s why we offer
          customized solutions tailored to meet your specific needs and
          objectives. Whether you’re a student seeking academic support or a
          business looking to boost your online presence, we’ll work closely
          with you to develop a solution that aligns with your goals.
        </Description>
      </TextContainer>
      {/* </FadeInUpWrapper> */}
    </ServicesSectionContainer>
  );
};

export default withScrollAnimation(ChooseParteners);
