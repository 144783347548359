import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import withScrollAnimation from './withScrollAnimation';
import { useCurrentRoute } from '../utilities';
import { useNavigate } from 'react-router';
import useResponsiveScreen from '../hooks/useResponsiveScreen';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const OuterContainer = styled.section`
  position: relative;
  padding: 3rem 1.25rem;
  display: flex;
  justify-content: center;
  ${({ currentRoute }) =>
    currentRoute !== 'services' &&
    css`
      background: linear-gradient(-45deg, #edfaff, white, #a9cdde, #edfaff);
      background-size: 400% 400%;
      animation: ${gradientAnimation} 15s ease infinite;
    `}
`;

const InnerContainer = styled.div`
  background: ${({ currentRoute }) =>
    currentRoute === 'services' ? '#f0f0f0' : '#87ceeb'};
  border-radius: 1.25rem;
  padding: 3rem 1.25rem;
  max-width: 66rem; /* 1050px in rem */
  width: 100%;
  text-align: center;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
`;

const Title = styled.h2`
  color: #1b3a4b;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 0.625rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.h3`
  color: #1b3a4b;
  font-size: 2.5rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 1.2rem;
  }
`;

const Description = styled.p`
  color: #555;
  font-size: 1rem;
  margin-bottom: 2.5rem;
  line-height: 1.6;

  @media (max-width: 450px) {
    font-size: 0.9rem;
    line-height: 1.2;
  }
`;

const Button = styled.button`
  background-color: #1b3a4b;
  color: white;
  border: none;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 450px) {
    margin-bottom: 1rem;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 1.25rem;
  img {
    width: 6.25rem;
    height: auto;
  }
`;

const ServiceCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  @media (max-width: 48rem) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  border-radius: 0.625rem;
  padding: 1.25rem;
  text-align: center;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  @media (max-width: 450px) {
    padding: 0.9rem;
  }
  img {
    width: 3.125rem;
    height: 3.125rem;
    margin-bottom: 0.625rem;
  }

  h4 {
    color: #1b3a4b;
    font-size: 1.2rem;
    margin-bottom: 0.625rem;
    @media (max-width: 768px) {
      font-size: 1.1rem;
      margin-bottom: 0.325rem;
    }
    @media (max-width: 450px) {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  p {
    color: #555;
    font-size: 1rem;
    line-height: 1.6;
    @media (max-width: 768px) {
      line-height: 1.5;
    }
    @media (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
`;

const ServicesOffer = () => {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveScreen();

  return (
    <OuterContainer currentRoute={currentRoute}>
      <InnerContainer currentRoute={currentRoute}>
        {currentRoute !== 'services' && (
          <>
            <ImageContainer>
              <img
                src={require('../assets/serviceOfferHeader_home.png')}
                alt='What they said about us'
              />
            </ImageContainer>
            <Title>What they said about us</Title>
          </>
        )}
        <Subtitle>What services do we offer?</Subtitle>
        <Description>
          At Tech Star Growth, we offer a comprehensive suite of {!isMobile ? <br />:""}
          services designed to meet your diverse online needs.
        </Description>
        <Button onClick={() => navigate('/about')}>Learn More</Button>
        <ServiceCardsContainer>
          <ServiceCard>
            <img
              src={require('../assets/serviceOffer_home1.png')}
              alt='Assignment Writer'
            />
            <h4>Assignment Writer</h4>
            <p>
              Our team of skilled writers is dedicated to delivering top-notch,
              tailored solutions to meet your academic needs.
            </p>
          </ServiceCard>
          <ServiceCard>
            <img
              src={require('../assets/serviceOffer_home2.png')}
              alt='Content Writer'
            />
            <h4>Content Writer</h4>
            <p>
              We combine creativity with strategic insights to deliver content
              that resonates with your target audience.
            </p>
          </ServiceCard>
          <ServiceCard>
            <img
              src={require('../assets/serviceOffer_home3.png')}
              alt='Data Entry'
            />
            <h4>Data Entry</h4>
            <p>
              Our experienced team specializes in accurately capturing,
              organizing, and managing data to meet your specific requirements.
            </p>
          </ServiceCard>
          <ServiceCard>
            <img
              src={require('../assets/linkSharing.png')} // Update the path to the generated image
              alt='Link Sharing'
            />
            <h4>Link Sharing</h4>
            <p>
              We provide efficient link sharing services to boost your online
              visibility and engagement.
            </p>
          </ServiceCard>
          <ServiceCard>
            <img
              src={require('../assets/adsPosting_services.png')} // Update the path to the generated image
              alt='Ads Posting'
            />
            <h4>Ads Posting</h4>
            <p>
              Our ads posting service ensures your advertisements reach the
              right audience for maximum impact.
            </p>
          </ServiceCard>
          <ServiceCard>
            <img
              src={require('../assets/manualTyping_services.png')} // Update the path to the generated image
              alt='Manual Typing'
            />
            <h4>Manual Typing</h4>
            <p>
              We offer precise manual typing services to ensure accurate and
              timely document creation.
            </p>
          </ServiceCard>
        </ServiceCardsContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

export default withScrollAnimation(ServicesOffer);
