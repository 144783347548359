import React from 'react'
import About from '../components/About'
import Journey from '../components/Journey';
import PresidentMessage from '../components/PresidentMessage';
import Mission from '../components/Mission';
import Values from '../components/Values';
import TestimonialsSection from '../basics/TestimonialsSection';
import JoinSection from '../basics/JoinSection';
const AboutUs = () => {
  return (
    <>
      <About />
      <Journey />
      <PresidentMessage />
      <Mission />
      <Values />
      <TestimonialsSection />
      <JoinSection />
    </>
  );
}

export default AboutUs
